<template>
  <div class="view view--platform view--platform-dx">
    <section class="section">
      <div class="container">
        <div class="row mb-4 mb-md-5">
          <div class="col-12 col-md-7">
            <div class="wrapper wrapper--v-center">
              <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s1.tit[0]" />
              <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s1.tit[1]" />
              <p class="txt fw-400 fc-gray mb-4" v-html="cc.s1.txt" />
              <div class="d-none d-md-block">
                <btn :data="cc.s1.btn" :theme="{type: 1, size: 'md', width: 'fc'}"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 mb-4 mb-md-0">
            <div class="wrapper wrapper--v-center">
              <div class="img img--platform-dx">
                <img src="/img/views/img-bony-s1.png" alt="notebook" />
              </div>
            </div>
          </div>
          <div class="col-12 d-md-none">
            <btn :data="cc.s1.btn" :theme="{type: 1, size: 'md', width: 'fc'}"/>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="block block--platform-alleviate">
              <div class="row">
                <div class="col-12 col-lg-8 mb-5">
                  <h2 class="tit fw-500 fc-white ts-26 ts-md-32 mb-4" v-html="cc.s2.benefit.tit" />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="wrapper wrapper--v-end">
                    <btn :data="cc.s2.benefit.btn" :theme="{type: 3, size: 'md', width: 260}" class="ms-md-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Btn from '@/components/Btn'
export default {
  components: {
    Btn
  },
  computed: {
    cc () {
      return this.$t('views.tools.education.bony')
    },
    alleviate () {
      return this.$t('components.alleviate')
    },
    features () {
      const items = this.cc.s4.cards
      const splitPoint = Math.round(items.length / 2)
      return [items.splice(0, splitPoint), items]
    }
  },
  metaInfo () {
    const meta = this.$t('meta.platform.dxmercato')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
